import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Layout from "../components/layout"
import SEO from "../components/seo"

const Scene = () => (
  <Layout>
    <SEO title="Scene" />
    <h1>Scene</h1>
    <ul>
        <li><FontAwesomeIcon icon="dice-one" size="3x" /></li>
        <li><FontAwesomeIcon icon="dice-two" size="3x" /></li>
        <li><FontAwesomeIcon icon="dice-three" size="3x" /></li>
        <li><FontAwesomeIcon icon="dice-four" size="3x" /></li>
        <li><FontAwesomeIcon icon="dice-five" size="3x" /></li>
        <li><FontAwesomeIcon icon="dice-six" size="3x" /></li>
    </ul>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default Scene
